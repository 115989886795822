import request from '@/utils/request'

/**
 * 运营 订单详情
 * @returns {*}
 */
export function orderDetails (id, params) {
  return request({
    url: `order/details/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运营 订单对账详情接口
 * @returns {*}
 */
export function reconciliation_details (id, params) {
  return request({
    url: `order/reconciliation_details/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运营 订单详情 照片审核信息 获取订单照片信息接口
 * @returns {*}
 */
export function get_picture (id, params) {
  return request({
    url: `order/get_picture/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 订单详情 订单照片信息审核通过接口
 * @returns {*}
 */
export function picture_pass (id, params) {
  return request({
    url: `order/picture_pass/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 订单详情 订单照片信息审核不通过接口
 * @returns {*}
 */
export function picture_flunk (id,data) {
  return request({
    url: `order/picture_flunk/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运营 订单详情 新增Or修改订单照片接口
 * @returns {*}
 */
export function update_picture (id,data) {
  return request({
    url: `order/update_picture/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运营 订单详情 上传客户或门头照接口
 * @returns {*}
 */
export function update_group_photo (id,data) {
  return request({
    url: `order/update_group_photo/${id}`,
    method: 'post',
    data
  })
}


/**
 * 运营 订单详情 订单取消操作
 * @returns {*}
 */
export function order_cancel (id,data) {
  return request({
    url: `order/order_cancel/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运营 订单详情 订单空驶
 * @returns {*}
 */
export function order_unloaded (id,data) {
  return request({
    url: `order/order_unloaded/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运营 订单详情 订单空驶
 * @returns {*}
 */
export function order_verbal (id,params) {
  return request({
    url: `order/order_verbal/${id}`,
    method: 'get',
    params
  })
}



/**
 * 运营 订单详情 公里信息
 * @returns {*}
 */
export function details_kilometre (id, params) {
  return request({
    url: `order/details_kilometre/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 订单详情 基本信息编辑
 * @returns {*}
 */
export function Editupdate_basics (id,data) {
  return request({
    url: `order/update_basics/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运营 订单详情 接单信息编辑
 * @returns {*}
 */
export function Editupdate_join_info (id,data) {
  return request({
    url: `order/update_join_info/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运营 订单详情 新增报备接口
 * @returns {*}
 */
export function Savereport (id,data) {
  return request({
    url: `order/report/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运营 订单详情 报备审核
 * @returns {*}
 */
export function Savereport_audit (id,data) {
  return request({
    url: `order/report_audit/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运营 订单详情关联订单
 * @returns {*}
 */
export function getget_existing (params) {
  return request({
    url: `order/order_list`,
    method: 'get',
    params
  })
}
/**
 * 运营 订单详情关联订单 添加关联
 * @returns {*}
 */
export function Savecorrelation_existing (id, params) {
  return request({
    url: `order/correlation/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运营 订单详情关联订单 取消关联
 * @returns {*}
 */
export function Savecorrelation_cancel (id, params) {
  return request({
    url: `order/correlation_cancel/${id}`,
    method: 'get',
    params
  })
}


/**
 * 订单 获取订单列表自定义表头接口
 * @returns {*}
 */
export function order_list_header (params) {
  return request({
    url: 'order/order_list_header',
    method: 'get',
    params
  })
}

/**
 * 运营 订单待接单 列表
 * @returns {*}
 */
export function getdelay_join (params) {
  return request({
    url: 'order/delay_join/list',
    method: 'get',
    params
  })
}
/**
 * 运营 订单 待接单 受理 详情
 * @returns {*}
 */
export function getread (id, params) {
  return request({
    url: `order/delay_join/read/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运营 订单 待接单 受理操作
 * @returns {*}
 */
export function Saveaccept (id,data) {
  return request({
    url: `order/delay_join/accept/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运营 订单 待接单 受理 编辑
 * @returns {*}
 */
export function Editdelay_join (id,data) {
  return request({
    url: `order/delay_join/edit/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运营 订单 待接单 拒绝
 * @returns {*}
 */
export function delay_joinreject (id,data) {
  return request({
    url: `order/delay_join/reject/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运营 订单 待接单 受理 派单 自营司机列表
 * @returns {*}
 */
export function getget_send_driver (id,params) {
  return request({
    url: `capacity/self_driver/get_send_driver/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运营 订单 待接单 受理 派单 共享司机列表
 * @returns {*}
 */
export function getshare_driver (id,params) {
  return request({
    url: `capacity/share_driver/get_send_driver/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 订单 待接单 受理 派单 合作司机列表
 * @returns {*}
 */
export function getoutsource_driver (id,params) {
  return request({
    url: `capacity/outsource_driver/get_send_driver/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 订单 待接单 受理 派单 外协司机列表
 * @returns {*}
 */
export function getclone_driver (id,params) {
  return request({
    url: `capacity/clone_driver/get_send_driver/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运营 订单 待接单 受理 派单接口
 * @returns {*}
 */
export function Saveaccept_edit (id,data) {
  return request({
    url: `order/delay_join/accept_edit/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运营 订单 待派单 派单接口
 * @returns {*}
 */
export function Savesend (id,data) {
  return request({
    url: `order/delay_send/send/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运营 订单 待接单 受理 派单 服务商列表
 * @returns {*}
 */
export function getget_cooperation (params) {
  return request({
    url: `capacity/facilitator/get_cooperation`,
    method: 'get',
    params
  })
}

/**
 * 运营 订单 待接单 受理 新增修理厂接口
 * @returns {*}
 */
export function Saverepair (id,data) {
  return request({
    url: `order/delay_send/repair/${id}`,
    method: 'post',
    data
  })
}


/**
 * 运营 下单 添加
 * @returns {*}
 */
export function Adddelay_join (data) {
  return request({
    url: `order/send_order`,
    method: 'post',
    data
  })
}

/**
 * 订单 所有订单 列表
 * @returns {*}
 */
export function getall (params) {
  return request({
    url: 'order/order_all',
    method: 'get',
    params
  })
}


/**
 * 运营 待派单 列表
 * @returns {*}
 */
export function getdelay_send (params) {
  return request({
    url: 'order/delay_send/list',
    method: 'get',
    params
  })
}

/**
 * 运营 待完成 列表
 * @returns {*}
 */
export function getdelay_finish (params) {
  return request({
    url: 'order/delay_finish/list',
    method: 'get',
    params
  })
}

/**
 * 运营 待回访 列表
 * @returns {*}
 */
export function getdelay_visit (params) {
  return request({
    url: 'order/delay_visit/list',
    method: 'get',
    params
  })
}
/**
 * 运营 待回访 列表 回访操作
 * @returns {*}
 */
export function Savevisit (id,data) {
  return request({
    url: `order/delay_visit/visit/${id}`,
    method: 'post',
    data
  })
}


/**
 * 运营 待审核 列表
 * @returns {*}
 */
export function getdelay_audit (params) {
  return request({
    url: 'order/delay_audit/list',
    method: 'get',
    params
  })
}
/**
 * 运营 待审核 获取审核配置
 * @returns {*}
 */
export function getcheck_assist (id, params) {
  return request({
    url: `order/delay_audit/check_assist/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运营 待审核 审核合格操作
 * @returns {*}
 */
export function audit (id,data) {
  return request({
    url: `order/delay_audit/pass/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运营 待审核 审核不合格操作
 * @returns {*}
 */
export function disqualification (id,data) {
  return request({
    url: `order/delay_audit/flunk/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运营 待审核 返回初审
 * @returns {*}
 */
export function Saveback_check (id,data) {
  return request({
    url: `order/delay_recheck/${id}`,
    method: 'post',
    data
  })
}


/**
 * 运营 待复审 列表
 * @returns {*}
 */
export function getdelay_recheck (params) {
  return request({
    url: 'order/delay_recheck/list',
    method: 'get',
    params
  })
}
/**
 * 运营 待复审 审核合格操作
 * @returns {*}
 */
export function delay_recheckaudit (id,data) {
  return request({
    url: `order/delay_recheck/pass/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运营 待复审 审核不合格操作
 * @returns {*}
 */
export function delay_recheckdisqualification (id,data) {
  return request({
    url: `order/delay_recheck/flunk/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运营 待确认 列表
 * @returns {*}
 */
export function getdelay_affirm (params) {
  return request({
    url: 'order/delay_affirm/list',
    method: 'get',
    params
  })
}

/**
 * 运营 待确认 确认
 * @returns {*}
 */
export function delayAffirm (id) {
  return request({
    url: `order/delay_affirm/affirm/${id}`,
    method: 'get',
  })
}

/**
 * 运营 待确认 详情
 * @param id
 * @param params
 * @returns {*}
 */
export function delayAppealInfoApi (id, params) {
  return request({
    url: `order/delay_affirm/appeal_info/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 待确认 申诉接口
 * @returns {*}
 */
export function appeal (id,data) {
  return request({
    url: `order/delay_affirm/appeal/${id}`,
    method: 'post',
    data
  })
}


/**
 * 运营 待确认 列表
 * @returns {*}
 */
export function getcancelled (params) {
  return request({
    url: 'order/cancelled/list',
    method: 'get',
    params
  })
}

/**
 * 运营 异常订单 列表
 * @returns {*}
 */
export function getabnormal (params) {
  return request({
    url: 'order/abnormal/list',
    method: 'get',
    params
  })
}
/**
 * 运营 异常订单 获取异常订单头部统计接口
 * @returns {*}
 */
export function header_statistics (params) {
  return request({
    url: 'order/abnormal/header_statistics',
    method: 'get',
    params
  })
}

/**
 * 运营 异常订单 详情
 * @returns {*}
 */
export function abnormaldetails (id, params) {
  return request({
    url: `order/abnormal/details/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 异常订单 详情 上传接单状态
 * @returns {*}
 */
export function upload_driver_status (type,data) {
  return request({
    url: `order/abnormal/upload_driver_status/${type}`,
    method: 'post',
    data
  })
}
/**
 * 运营 异常订单 详情 获取异常订单司机坐标接口
 * @returns {*}
 */
export function get_driver_coord (id, params) {
  return request({
    url: `order/abnormal/get_driver_coord/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 异常订单 详情 异常订单处理接口
 * @returns {*}
 */
export function order_manage (id,data) {
  return request({
    url: `order/abnormal/order_manage/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运营 投诉订单 列表
 * @returns {*}
 */
export function getcomplaint (params) {
  return request({
    url: 'order/delay_audit/list',
    method: 'get',
    params
  })
}

/**
 * 运营 申请审核 列表
 * @returns {*}
 */
export function getapplyfor (params) {
  return request({
    url: 'order/delay_audit/list',
    method: 'get',
    params
  })
}



/**
 * 订单 获取服务商下拉列表
 * @returns {*}
 */
export function get_partner (params) {
  return request({
    url: 'order/get_partner',
    method: 'get',
    params
  })
}
/**
 * 订单 获取合作服务商司机接口
 * @returns {*}
 */
export function get_driver (id, params) {
  return request({
    url: `order/get_driver/${id}`,
    method: 'get',
    params
  })
}

/**
 * 订单 根据合作客户获取救援类型列表接口
 * @returns {*}
 */
export function getrescue_type (id, params) {
  return request({
    url: `order/rescue_type/${id}`,
    method: 'get',
    params
  })
}
/**
 * 订单 根据客户获取对应服务项目单双地址接口
 * @returns {*}
 */
export function get_services_config (id, params) {
  return request({
    url: `common/get_services_config/${id}`,
    method: 'get',
    params
  })
}

/**
 * 订单 获取待接单受理信息接口
 * @returns {*}
 */
export function delay_joinread (id, params) {
  return request({
    url: `order/delay_join/read/${id}`,
    method: 'get',
    params
  })
}




/**
 * 订单 新增订单投诉接口
 * @returns {*}
 */
export function create_complaint (id,data) {
  return request({
    url: `order/create_complaint/${id}`,
    method: 'post',
    data
  })
}

/**
 * 订单 改抢单接口
 * @returns {*}
 */
export function order_rob (id,data) {
  return request({
    url: `order/order_rob/${id}`,
    method: 'post',
    data
  })
}


/**
 * 订单 派单地图列表接口
 * @returns {*}
 */
export function delay_send_map (params) {
  return request({
    url: 'order/delay_send_map/map',
    method: 'get',
    params
  })
}
/**
 * 订单 派单地图角标接口
 * @returns {*}
 */
export function corner (params) {
  return request({
    url: 'order/delay_send_map/corner',
    method: 'get',
    params
  })
}

/**
 * 订单 派单地图时效统计接口
 * @returns {*}
 */
export function counts (params) {
  return request({
    url: 'order/delay_send_map/counts',
    method: 'get',
    params
  })
}

/**
 * 订单 派单地图订单详情接口
 * @returns {*}
 */
export function delay_send_mapdetails (id, params) {
  return request({
    url: `order/delay_send_map/details/${id}`,
    method: 'get',
    params
  })
}

/**
 * 订单 新增订单跟进记录接口
 * @returns {*}
 */
export function order_follow (id,data) {
  return request({
    url: `order/order_follow/${id}`,
    method: 'post',
    data
  })
}

/**
 * 订单 订单催单提醒接口
 * @returns {*}
 */
export function order_reminder (id,data) {
  return request({
    url: `order/order_reminder/${id}`,
    method: 'post',
    data
  })
}

/**
 * 订单 订单催单回复接口
 * @returns {*}
 */
export function reminder_reply (id,data) {
  return request({
    url: `order/reminder_reply/${id}`,
    method: 'post',
    data
  })
}


/**
 * 抢单大厅 抢单列表接口
 * @returns {*}
 */
export function roblist (params) {
  return request({
    url: 'order/rob/list',
    method: 'get',
    params
  })
}

/**
 * 抢单大厅 授信额度查询接口
 * @returns {*}
 */
export function operation_money (params) {
  return request({
    url: 'order/rob/operation_money',
    method: 'get',
    params
  })
}

/**
 * 订单抢单接口
 * @returns {*}
 */
export function rob (id,data) {
  return request({
    url: `order/rob/rob/${id}`,
    method: 'post',
    data
  })
}

/**
 * 订单 聚合订单列表接口
 * @returns {*}
 */
export function aggregation_list (params) {
  return request({
    url: 'order/rob/aggregation_list',
    method: 'get',
    params
  })
}


/**
 * 订单 订单统计
 * @returns {*}
 */
export function order_statistics (params) {
  return request({
    url: 'order/order_statistics',
    method: 'get',
    params
  })
}

/**
 * 抢单大厅开通会员
 * @returns {*}
 */
export function rob_member_open (params) {
  return request({
    url: 'platform/rob_member_open',
    method: 'get',
    params
  })
}



/**
 * 检测工单会员是否开通接口
 * @returns {*}
 */
export function inquire_work_member (params) {
  return request({
    url: 'platform/inquire_work_member',
    method: 'get',
    params
  })
}
/**
 * 电子工单支付
 * @returns {*}
 */
export function work_member_open (params) {
  return request({
    url: 'platform/work_member_open',
    method: 'get',
    params
  })
}

/**
 * 获取免责条款接口
 * @returns {*}
 */
export function get_work_disclaimer (params) {
  return request({
    url: 'platform/get_work_disclaimer',
    method: 'get',
    params
  })
}
/**
 * 新增OR更新免责条款接口
 * @returns {*}
 */
export function save_work_disclaimer (data) {
  return request({
    url: `platform/save_work_disclaimer`,
    method: 'post',
    data
  })
}

/**
 * 系统服务商余额充值接口
 * @returns {*}
 */
export function recharge (data) {
  return request({
    url: `platform/recharge`,
    method: 'post',
    data
  })
}


/**
 * 服务商资源购买接口
 * @returns {*}
 */
export function resource_buy (type,data) {
  return request({
    url: `platform/resource_buy/${type}`,
    method: 'post',
    data
  })
}

/**
 * 开票资料下拉列表接口
 * @returns {*}
 */
export function get_invoice_select (params) {
  return request({
    url: 'capacity/facilitator/get_invoice_select',
    method: 'get',
    params
  })
}

/**
 * 开票接口
 * @returns {*}
 */
export function billing_invoice (id,data) {
  return request({
    url: `platform/facilitator/billing_invoice/${id}`,
    method: 'post',
    data
  })
}




/**
 * 订单 我的任务
 * @returns {*}
 */
export function my_tasklist (params) {
  return request({
    url: 'order/my_task/list',
    method: 'get',
    params
  })
}

/**
 * 订单 获取同部门员工列表接口
 * @returns {*}
 */
export function get_staff_list (params) {
  return request({
    url: 'order/my_task/get_staff_list',
    method: 'get',
    params
  })
}
/**
 * 转交或批量转交接口
 * @returns {*}
 */
export function transfer_to (data) {
  return request({
    url: `order/my_task/transfer_to`,
    method: 'post',
    data
  })
}
