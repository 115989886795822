<template>
  <div style="padding: 20px;">
    <div class="title">
      {{ $route.meta.title }}
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <div style="display: flex;align-items: center;">
        <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
        <span style="font-size: 20px;font-weight: 700;color: #202033;">电子工单通用模板</span>
        <span style="width: 134px;background: #53d1a1;border-radius: 6px;padding-top: 8px;padding-bottom: 8px;font-size: 14px;font-weight: 400;color: #ffffff;display: inline-block;text-align: center;cursor: pointer;margin-left: 20px;" v-if="isShow == true" @click="To">支付168元开通/年</span>
        <span style="margin-left: 20px;color: red;" v-if="isShow == true">*限时优惠168原价288(支持定制化)</span>
      </div>

      <div style="margin-top: 10px; text-align: center;padding: 5px;;">
        <!--<el-form ref="form" :model="form" label-width="125px">-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="8">-->
        <!--      <el-form-item label="模板设置">-->
        <!--        <el-input v-model="form.name" placeholder="输入模板标题文字"></el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--    <el-col :span="8">-->
        <!--      <el-input v-model="form.name1" placeholder="输入企业名称"></el-input>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="8">-->
        <!--      <el-form-item label="请选择logo显示位置">-->
        <!--        <el-select v-model="form.value" placeholder="请选择">-->
        <!--          <el-option-->
        <!--              v-for="item in options"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value">-->
        <!--          </el-option>-->
        <!--        </el-select>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="8">-->
        <!--      <el-form-item label="logo">-->
        <!--        <el-upload-->
        <!--            :action="action"-->
        <!--            :headers="headers"-->
        <!--            accept=".jpg, .png, jpeg"-->
        <!--            :on-success="successUpload"-->
        <!--            :limit="1"-->
        <!--            :file-list="fileList"-->
        <!--            :class="{hide_box: upload_btn}"-->
        <!--            :on-change="change"-->
        <!--            list-type="picture-card"-->
        <!--            :on-preview="handlePictureCardPreview"-->
        <!--            :on-remove="handleRemove">-->
        <!--          <i class="el-icon-plus"></i>-->
        <!--        </el-upload>-->
        <!--        <el-dialog :visible.sync="dialogVisible">-->
        <!--          <img width="100%" :src="dialogImageUrl" alt="">-->
        <!--        </el-dialog>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="8">-->
        <!--      <el-form-item label="选择开通使用的模板" label-width="130px">-->
        <!--        <el-radio v-model="radio" label="1">模板1</el-radio>-->
        <!--        <el-radio v-model="radio" label="2">薄板2</el-radio>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--</el-form>-->
        <div style="margin-top: 10px; text-align: center;padding: 5px;;">
          <div style="margin-bottom: 20px;">
            <span style="font-size: 20px;font-weight: 700;color: #202033;">道路救援服务工单</span>
            <i class="el-icon-edit margin-l" @click="openEdit('名称')"></i>
          </div>

          <!-- 表格 -->
          <table class="table" style="width: 100%;text-align: left; border-collapse: collapse;">
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px; border: 1px solid #1e1e1e;">logo</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;" colspan="3">
                <img src="@/assets/images/home/logo.png" alt="" style="width: 50px; height: 50px;vertical-align: middle;" v-viewer>
                <i class="el-icon-edit margin-l1" @click="openEdit('logo')"></i>
              </td>
              <td style="width: 10%;text-align: center;padding: 5px; border: 1px solid #1e1e1e;">所属客户</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">山东平安</td>
            </tr>
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px; border: 1px solid #1e1e1e;">报案时间</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">202X年09月03日 16:36:19</td>
              <td style="width: 10%;text-align: center;padding: 5px; border: 1px solid #1e1e1e;">电子单号</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">CIC202309091639196522xx</td>
              <td style="width: 10%;text-align: center;padding: 5px; border: 1px solid #1e1e1e;">报案号后6位</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">639729</td>
            </tr>
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;border: 1px solid #1e1e1e;">客户姓名</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">康熙</td>
              <td style="width: 10%;text-align: center;padding: 5px;border: 1px solid #1e1e1e;">客户电话</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">138****0000</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;"></td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;"></td>
            </tr>
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;border: 1px solid #1e1e1e;">车牌号码</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">粤AXXXXX</td>
              <td style="width: 10%;text-align: center;padding: 5px;border: 1px solid #1e1e1e;">车架后6位</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">30**18</td>
              <td style="width: 10%;text-align: center;padding: 5px;border: 1px solid #1e1e1e;">品牌车型</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">宝骏LZW7128KFA轿车</td>
            </tr>
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;border: 1px solid #1e1e1e;border-bottom: none;">派工时间</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;"></td>
              <td style="width: 10%;text-align: center;padding: 5px;">到场时间</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">202X年09月09日 17:16:55</td>
              <td style="width: 10%;text-align: center;padding: 5px;">完成时间</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">202X年09月09日 17:16:55</td>
            </tr>
          </table>

          <table class="table" style="width: 100%;text-align: left; border-collapse: collapse;">
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;; border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">业务来源</td>
              <td style="width: 90%;border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">舜行天下</td>
            </tr>
          </table>
          <table class="table" style="width: 100%;text-align: left; border-collapse: collapse;">
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;border: 1px solid #1e1e1e;border-bottom: none;">救援地点</td>
              <td style="width: 90%;border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">河北省秦皇岛市海港区惠民街与南岭路交叉口正西方向238米富立秦皇半岛7区</td>
            </tr>
          </table>
          <table class="table" style="width: 100%;text-align: left; border-collapse: collapse;">
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;border: 1px solid #1e1e1e;border-bottom: none;">目的地点</td>
              <td style="width: 90%;border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">河北省秦皇岛市海港区经济技术开发区渤海西道3号路能达(秦皇岛)国际汽车文化创意产业园</td>
            </tr>
          </table>

          <table class="table" style="width: 100%;text-align: left; border-collapse: collapse;">
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;;border: 1px solid #1e1e1e;padding: 5px;">服务项目</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">拖车救援</td>
              <td style="width: 10%;text-align: center;padding: 5px;;border: 1px solid #1e1e1e;padding: 5px;">处理结果</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">完成</td>
            </tr>
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;;border: 1px solid #1e1e1e;padding: 5px;">服务人员</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">韦小宝</td>
              <td style="width: 10%;text-align: center;padding: 5px;;border: 1px solid #1e1e1e;padding: 5px;">服务车牌</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;">粤AXXXXX</td>
            </tr>
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;;border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">前往救援地</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">4.00公里</td>
              <td style="width: 10%;text-align: center;padding: 5px;;border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">拖车里程数</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">7.00公里</td>
            </tr>
          </table>

          <table class="table" style="width: 100%;text-align: left; border-collapse: collapse;">
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;; border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">轨迹</td>
              <td style="width: 90%;border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">
                <img src="@/assets/images/home/map.png" alt="" style="width: 100%;height: 300px;">
              </td>
            </tr>
          </table>

          <table class="table" style="width: 100%;text-align: left; border-collapse: collapse;">
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;;border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">客户签字</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;"></td>
              <!--<td style="width: 10%;text-align: center;padding: 5px;;border: 1px solid #1e1e1e;padding: 5px;">接车人签字</td>-->
              <td style="width: 10%;text-align: center;padding: 5px;;border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">司机签字</td>
              <td style="border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;"></td>
            </tr>
          </table>

          <!--<table class="table" style="width: 100%;text-align: left; border-collapse: collapse;">-->
          <!--  <tr>-->
          <!--    <td style="width: 10%;text-align: center;padding: 5px;; border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;">司机签字</td>-->
          <!--    <td style="width: 90%;border: 1px solid #1e1e1e;padding: 5px;border-bottom: none;"></td>-->
          <!--  </tr>-->
          <!--</table>-->

          <table class="table" style="width: 100%;text-align: left; border-collapse: collapse;">
            <tr>
              <td style="width: 10%;text-align: center;padding: 5px;; border: 1px solid #1e1e1e;padding: 5px;">客户声明</td>
              <td style="width: 90%;border: 1px solid #1e1e1e;padding: 5px;">1.在救援前请客户与救援服务人检查确认好车身情况，如客户隐瞒相关情况造成的损失由客户自行承担，因此给服务人造成损失，客户需进行赔偿 <br> 2.敦据过程中，建议客户将车内一切贵重物品携带保管好，以免发生纠纷，若发生丢失、毁坏，本司不承担任何责任 <br> 3.救援过程中，严禁客户坐在故车内，货运车辆严禁载货托运，我司不对此承担任何责任 <br> 4.救援完成后，客户或交车人需确认服务完成及车辆无损，若有受损需在救援服务单签字前及时向我司反馈，签字后视为服务已完成，我司不再对索赔承担任何责任。<br> 5.困境，吊车免责声明:在发生事故拖车，吊车，困境施救过程中所产生的二次损伤与救援公司无关，<i class="el-icon-edit margin-l1" @click="openEdit('声明')"></i></td>
            </tr>
          </table>

        </div>
      </div>

      <div style="margin-top: 20px;display: flex;align-items: center;margin-bottom: 10px;">
        <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
        <span style="font-size: 20px;font-weight: 700;color: #202033;">用户端免责条款</span>
      </div>
      <div>
        <div style="border: 1px solid #ccc;">
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="height: 500px; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
          />
        </div>
        <div style="margin-top: 20px;text-align: center;">
          <el-button>取消</el-button>
          <el-button
            style="margin-left: 20px;"
            type="primary"
            @click="save()">保存
          </el-button>
        </div>
      </div>
    </el-card>

    <el-dialog
      title="修改"
      :close-on-click-modal="false"
      :visible.sync="isShowEdit"
      width="30%"
      :before-close="handleClose">
      <el-form ref="form" :model="form" label-width="105px">
        <el-form-item label="模板设置:" v-if="editText === '名称'">
          <el-input v-model="form.name" placeholder="输入模板标题文字"></el-input>
        </el-form-item>
        <el-form-item label="logo:" v-if="editText === 'logo'">
          <el-upload
            :action="action"
            :headers="headers"
            accept=".jpg, .png, jpeg"
            :on-success="successUpload"
            :limit="1"
            :file-list="fileList"
            :class="{hide_box: upload_btn}"
            :on-change="change"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
        <el-form-item label="客户声明:" v-if="editText === '声明'">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            clearable
            v-model="form.textarea2">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="isShowEdit = false">取 消</el-button>
        <el-button type="primary" @click="isShowEdit = false">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

// 引入的接口
import {inquire_work_member, work_member_open, get_work_disclaimer, save_work_disclaimer} from '@/api/orderModule'

import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

import { DomEditor } from '@wangeditor/editor'

export default {
  components: { Editor, Toolbar },
  data(){
    return{
      editor: null,
      html: '',
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        excludeKeys: ['group-image', 'uploadImage', 'insertImage', "uploadVideo", "insertVideo",'editVideoSize','group-video'/* 隐藏哪些菜单 */],
      },
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'

      isShow:false,

      form:{

      },

      options: [
        {
          value: '1',
          label: '左上角'
        },
        {
          value: '2',
          label: '右上角'
        },
        {
          value: '3',
          label: '左下角'
        },
        {
          value: '4',
          label: '右下角'
        },
      ],

      // 上传图片
      // logo
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList:[],

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/operate',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },
      logo:'',

      radio:'',

      //修改内容
      isShowEdit:false,
      editText:'',

    }
  },

  mounted() {
    this.getinfo();

    this.getget_work_disclaimer();
  },

  methods:{
    //logo
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    change(){
      // 上传成功后，隐藏上传按钮
      this.upload_btn = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.logo = file.data.urls;
    },

    //点击修改
    openEdit(value){
      // 修改的模块
      this.editText = value;
      // 显示修改弹窗
      this.isShowEdit = true;
    },
    //关闭修改
    handleClose(){
      this.isShowEdit = false;
    },


    // 检测工单会员是否开通接口
    getinfo(){
      inquire_work_member().then(res => {
        if(res.code === 200){
          if(res.data.money == false){
            this.isShow = true;
            this.$message.error(res.msg);
          }else {
            this.isShow = false;
          }
        }else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },

    //获取免责条款
    getget_work_disclaimer(){
      get_work_disclaimer().then(res => {
        if(res.code === 200){
          this.html = res.data.disclaimer;
        }else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },

    //跳转到支付页面
    To(){
      // this.$router.push('/ActivateService')
      let params = {
        return_url: window.location.href
      }
      work_member_open(params).then(res => {
        if(res.code === 200){
          console.log(res)
          window.location.href = res.data.return;
        }else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },

    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },

    save(){
      // inquire_work_member().then(res => {
      //   if(res.code === 200){
      //     if(res.data.money == false){
      //       this.isShow = true;
      //       this.$message.error(res.msg);
      //     }else {
      //       this.isShow = false;
      //       if(this.html == ''){
      //         this.$message.error('请填写免责条款');
      //       }else {
      //         let data = {
      //           disclaimer: this.html
      //         }
      //         save_work_disclaimer(data).then(res => {
      //           if(res.code === 200){
      //             this.$message.success(res.msg);
      //             this.getget_work_disclaimer();
      //           }else {
      //             this.$message.error(res.msg);
      //           }
      //         }).catch(e => {
      //           this.$message.error(e.msg);
      //         })
      //       }
      //     }
      //   }else {
      //     this.$message.error(res.msg);
      //   }
      // }).catch(e => {
      //   this.$message.error(e.msg);
      // })

      if(this.html == ''){
        this.$message.error('请填写免责条款');
      }else {
        let data = {
          disclaimer: this.html
        }
        save_work_disclaimer(data).then(res => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.getget_work_disclaimer();
          } else {
            this.$message.error(res.msg);
          }
        }).catch(e => {
          this.$message.error(e.msg);
        })
      }
    },


  },

  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }


}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
  //.table{
  //  width: 100%;
  //  text-align: left;
  //  table-layout: fixed;
  //  border-collapse: collapse;
  //  td{
  //    border: 1px solid #1e1e1e;
  //    padding: 5px;
  //    margin: 0;
  //  }
  //}

  .zuomargin{
    margin-left: 20px;
  }

  .hide_box /deep/ .el-upload--picture-card {
    display: none;
  }


  .margin-l{
    margin-left: 20px;
    font-size: 20px;
  }
  .margin-l1{
    margin-left: 20px;
    vertical-align: middle;
  }
</style>
